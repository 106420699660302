"use client";

import { useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "motion/react";
import { PlaySolid } from "iconoir-react";
import { childVariants } from "../_lib/animations";
import CustomButton from "./Button";
import Link from "next/link";
import VideoModal from "./VideoModal";
import { Button } from "@/components/ui";

export default function Hero() {
  const inputRef = useRef<null | HTMLInputElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handlePlayButtonClick = () => {
    setIsModalOpen(true);
    videoRef.current?.pause();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, []);

  return (
    <div className="flex flex-col items-center justify-center pt-4 md:flex-row md:items-center md:pb-8 md:pt-24">
      {/* Text Section */}
      <div className="flex flex-col text-left md:w-1/2 md:pr-8">
        <motion.h1
          className="bg-gradient-to-b font-heading from-sky-400 via-sky-400 to-violet-500 mt-16 md:mt-0 bg-clip-text text-left text-3xl font-extrabold text-transparent md:text-3xl"
          variants={childVariants}
        >
          Study Smarter, Not Harder with Personalized Learning
        </motion.h1>
        <motion.h2
          className="mt-4 text-lg font-semibold leading-loose text-grey-200 md:mt-8"
          variants={childVariants}
        >
          Effortlessly organize, study, and master complex material with
          adaptive flashcards, AI-powered quizzes, real-time feedback, and
          personalized course management tailored to your needs.
        </motion.h2>

        <div className="pt-4 md:pt-8 flex flex-col gap-2 items-center sm:flex-row sm:gap-4">
          <input
            ref={inputRef}
            type="email"
            placeholder="Enter your email"
            autoComplete="email"
            className="rounded-md p-2 h-10 mb-4 md:mb-0 text-base max-w-64  text-grey-100 w-full sm:w-64 border bg-base-black border-grey-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
          <Button
            className="rounded-md bg-blue-500 h-10 px-4 text-base-white text-center flex items-center justify-center transition-colors duration-150 ease-in-out hover:bg-blue-400 w-full sm:w-auto"
            type="button"
            disabled={true}
          >
            Join Waitlist
          </Button>
        </div>
        <p className="text-sm text-grey-300 italic my-2 pl-1">
          Sorry we've reached our waitlist capacity!
        </p>
      </div>

      {/* Video Section */}
      <motion.div
        className="flex w-full md:w-1/2 items-center justify-center py-4 md:py-0"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
        variants={childVariants}
      >
        <div className="w-full max-w-md md:max-w-xl lg:max-w-2xl h-auto aspect-w-16 aspect-h-9 relative">
          {/* Background Video */}
          <video
            autoPlay
            className="w-full h-full object-cover rounded-lg border border-grey-600"
            loop
            muted
            ref={videoRef}
            src="/videos/sia-background-video.webm"
          />

          {/* Play Button with Icon */}
          <button
            aria-label="Play Video"
            className="absolute inset-0 z-10 text-grey-300/70 flex items-center justify-center"
            onClick={handlePlayButtonClick}
            type="button"
          >
            <PlaySolid className="w-12 h-12" />
          </button>

          {/* Using VideoModal component */}
          <AnimatePresence>
            {isModalOpen && (
              <VideoModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                videoUrl="/videos/Fall_Demo.webm"
              />
            )}
          </AnimatePresence>
        </div>
      </motion.div>
    </div>
  );
}
