"use client";
import Link from "next/link";
import CustomButton from "./Button";
import { motion } from "framer-motion";

export default function CallToAction() {
  return (
    <motion.div
      className="flex flex-col items-center justify-center py-16"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      {/* Heading */}
      <h2 className="text-4xl font-bold font-heading text-base-white mb-4 text-center">
        Ready to Crush Your Next Exam?
      </h2>

      {/* Subtext */}
      <p className="text-grey-100 mb-6 text-center">
        Sign up and take your studying to the next level.
      </p>

      {/* Button */}
      <Link href="/signin">
        <CustomButton
          className="rounded-md bg-blue-500 p-4 py-2 text-base-white transition-colors duration-150 ease-in-out hover:scale-105 hover:bg-blue-400"
          type="button"
        >
          Get Free Access
        </CustomButton>
      </Link>
    </motion.div>
  );
}
