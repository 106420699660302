"use client";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { motion } from "motion/react";

interface FAQItem {
  question: string;
  answer: string;
}
const faqData: FAQItem[] = [
  {
    question:
      "How is Sia different from other tools like ChatGPT, UWorld, or Anki?",
    answer:
      "Sia is a learning management platform combined with personalized AI tools - you can upload content onto Sia, organize your content, label your content to help your AI understand how you learn, and study the content on Sia, allowing us to generate personalized insights. Sia unifies a vast ecosystem of study support and organizational tools, focusing on improving student performance, efficiency, and quality of life.",
  },
  {
    question: "How is Sia better than using ChatGPT for answering questions?",
    answer:
      "Sia allows you to access your content right there on the platform, so it’s more integrated into your learning workflows and much more personalized than a chatbot. Rather than countless iterations of uploading PDFs and asking questions, you can upload, organize and study your content on Sia. The AI models read the same information as you during study sessions, and can provide live support based on your content and other data, helping you identify your weaknesses.",
  },
  {
    question: "How personalized is the AI feedback?",
    answer:
      "Very personalized - it’s based on the content you upload, the descriptions you provide about your needs/challenges, the labels you give your content, and the way you interact with the AI features - consider Sia your educational health record, learning how you learn over time to identify challenges and enhance your experience.",
  },
  {
    question:
      "Can I add more materials to a course after I’ve already created it?",
    answer:
      "You can add content, delete content, or update tags at any time, for any course.",
  },
  {
    question: "Can I customize the flashcards and quizzes?",
    answer:
      "Yes, absolutely, using generative AI technology, Sia can create flashcard decks and quizzes based on your exact instructions.",
  },
  {
    question: "Is Sia secure?",
    answer:
      "Sia does not share your content/data with any third parties. We use reputable cloud providers and run security scans continuously through an enterprise partnership with Jit.",
  },
];

export default function FAQSection() {
  return (
    <div className="w-full mx-auto p-6" id="faq-section">
      <motion.h2
        className="text-2xl font-bold mb-6 font-heading"
        initial={{ opacity: 0, y: -20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.1 }}
        transition={{ duration: 0.7, ease: "easeOut" }}
      >
        Frequently Asked Questions
      </motion.h2>
      <Accordion className="w-full" collapsible={true} type="single">
        {faqData.map((item, index) => (
          <motion.div
            key={item.question}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.1 }}
            transition={{
              duration: 0.6,
              ease: "easeOut",
              delay: index * 0.2,
            }}
          >
            <AccordionItem value={`item-${index}`}>
              <AccordionTrigger className="text-left text-base">
                {item.question}
              </AccordionTrigger>
              <AccordionContent className="p-4 leading-loose text-base">
                {item.answer}
              </AccordionContent>
            </AccordionItem>
          </motion.div>
        ))}
      </Accordion>
    </div>
  );
}
