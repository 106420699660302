import type { ReactNode } from 'react';
import type React from 'react';
import { forwardRef } from 'react';

type CustomButtonProps = {
  children: ReactNode;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const CustomButton = forwardRef<HTMLButtonElement, CustomButtonProps>(
  ({ children, ...props }, ref) => (
    <button
      className="min-w-32 rounded bg-blue-500 px-4 py-2 font-semibold text-white transition-colors duration-150 ease-in-out hover:bg-blue-400"
      ref={ref}
      type="button"
      {...props}
    >
      {children}
    </button>
  )
);

CustomButton.displayName = 'CustomButton';
export default CustomButton;
