"use client";
import { useState, useEffect, useRef } from "react";

interface UseHideOnScrollOptions {
  threshold?: number;
  initialState?: boolean;
}

export const useHideOnScroll = ({
  threshold = 0,
  initialState = true,
}: UseHideOnScrollOptions) => {
  const [isVisible, setIsVisible] = useState(initialState);
  const lastScrollY = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const difference = currentScrollY - lastScrollY.current;

      if (difference < 0) {
        setIsVisible(true);
      } else if (Math.abs(difference) > threshold) {
        setIsVisible(false);
      }

      lastScrollY.current = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [threshold]);

  return isVisible;
};
