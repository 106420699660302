"use client";
import { motion } from "motion/react";
import AboutCourses from "./_components/AboutCourses";
import AboutLearningCenter from "./_components/AboutLearningCenter";
import CallToAction from "./_components/CallToAction";
import FAQSection from "./_components/FAQsection";
import Hero from "./_components/Hero";
import NavBar from "./_components/Navbar";
import Testimonial from "./_components/Testimonial";
import ThreeCardLayout from "./_components/ThreeCardLayout";

const staggerContainer = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.5,
    },
  },
};

export default function LandingPage() {
  return (
    <>
      <div className="bg-gradient-to-t from-sky-950 via-grey-900 to-base-black min-h-screen scroll-smooth">
        <div className="relative max-w-7xl mx-auto">
          <NavBar />
        </div>

        <motion.div
          className="relative px-10 py-8 sm:px-4 sm:py-16 max-w-7xl justify-center align-middle mx-auto"
          variants={staggerContainer}
          initial="hidden"
          animate="show"
        >
          <Hero />

          <ThreeCardLayout />
          <AboutCourses
            description="Whether you're tackling the intricacies of Molecular Biology or exploring the depths of English Literature, your personalized class awaits. It's education, designed by you, for you."
            title="Create Your Courses, Your Way"
          />
          <Testimonial />
          <FAQSection />
          <AboutLearningCenter />
          <CallToAction />
        </motion.div>
      </div>
    </>
  );
}
