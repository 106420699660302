import type { Variants } from "motion/react";

export const containerVariants: Variants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      delay: 0.7,
      staggerChildren: 0.4,
    },
  },
};

export const childVariants: Variants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
  },
};
