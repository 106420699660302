"use client";
import Image from "next/image";
import type React from "react";
import { motion } from "motion/react";
import * as Slot from "@radix-ui/react-slot";
import { forwardRef } from "react";

interface CardProps {
  src: string;
  alt: string;
  asChild?: boolean;
  children?: React.ReactNode;
}

interface CardHeaderProps {
  asChild?: boolean;
  children?: React.ReactNode;
}

interface CardDescriptionProps {
  asChild?: boolean;
  children?: React.ReactNode;
}

// Card subcomponents
const CardHeader: React.FC<CardHeaderProps> = ({ asChild, children }) => {
  const Component = asChild ? Slot.Slot : "h3";
  return (
    <Component className="text-xl font-bold font-heading text-center text-sky-500 min-h-[60px]">
      {children}
    </Component>
  );
};

const CardDescription: React.FC<CardDescriptionProps> = ({
  asChild,
  children,
}) => {
  const Component = asChild ? Slot.Slot : "p";
  return (
    <Component className="leading-relaxed text-center text-grey-200">
      {children}
    </Component>
  );
};

const Card = forwardRef<HTMLDivElement, CardProps>(
  ({ src, alt, children, asChild = false }, ref) => {
    const Component = asChild ? Slot.Slot : "div";
    return (
      <Component
        ref={ref}
        className="bg-grey-800/10 backdrop-blur-md border border-grey-600 shadow-xl mb-10 grow rounded-xl p-6 transition-all duration-300 hover:scale-105 hover:bg-grey-700/20"
      >
        <div className="flex flex-col items-center">
          <Image
            alt={alt}
            className="drop-shadow-lg"
            height={125}
            src={src}
            width={125}
          />
          {children}
        </div>
      </Component>
    );
  },
);

Card.displayName = "Card";

// Usage in ThreeCardLayout
function ThreeCardLayout() {
  return (
    <>
      <div className="pb-8 text-center">
        <motion.h2
          className="text-2xl font-semibold font-heading"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, ease: "easeOut" }}
        >
          Tailored for Premed, Medical, Nursing, and PA Students.
        </motion.h2>
        <motion.h3
          className="text-lg text-grey-300"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, ease: "easeOut", delay: 0.2 }}
        >
          Experience personalized AI-enhanced learning at your fingertips.
        </motion.h3>
      </div>
      <div className="flex flex-col md:flex-row md:space-x-8">
        {[
          {
            alt: "Flashcard Image",
            src: "/images/book-icon.svg",
            header: "Effortlessly Organize Your Courses",
            description:
              "Keep all your study materials in one place with streamlined course management.",
          },
          {
            alt: "Quiz Image",
            src: "/images/quiz-icon.svg",
            header: "Boost Retention with AI-powered Flashcards and Quizzes",
            description:
              "Reinforce key concepts using adaptive flashcards and quizzes for smarter studying.",
          },
          {
            alt: "Sia Chat",
            src: "/images/chat-icon.svg",
            header: "Get Personalized Guidance with AI Learning Assistance",
            description:
              "Get tailored study recommendations and feedback based on your unique needs.",
          },
        ].map((card, index) => (
          <motion.div
            key={card.alt}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, ease: "easeOut", delay: index * 0.2 }}
          >
            <Card alt={card.alt} src={card.src}>
              <CardHeader>{card.header}</CardHeader>
              <CardDescription>{card.description}</CardDescription>
            </Card>
          </motion.div>
        ))}
      </div>
    </>
  );
}

export default ThreeCardLayout;
