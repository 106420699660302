"use client";

import { motion } from "motion/react";
import Image from "next/image";
import LearningCenterImage from "@/public/images/learningcenter.webp";

const AboutLearningCenter: React.FC = () => {
  return (
    <motion.div
      className="flex flex-col lg:flex-row items-center lg:items-center px-6 py-12"
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, amount: 0.1 }}
      transition={{ duration: 0.7, ease: "easeOut" }}
    >
      {/* Left Side - Image */}
      <motion.div
        className="w-full lg:w-3/5 mb-8 lg:mb-0 lg:mr-8"
        initial={{ opacity: 0, x: -20 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true, amount: 0.1 }}
        transition={{ duration: 0.7, ease: "easeOut" }}
      >
        <Image
          alt="Learning Center"
          height={400}
          src={LearningCenterImage}
          width={700}
        />
      </motion.div>

      {/* Right Side - Copy */}
      <motion.div
        className="w-full lg:w-2/5 flex flex-col justify-center"
        initial={{ opacity: 0, x: 20 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true, amount: 0.1 }}
        transition={{ duration: 0.7, ease: "easeOut" }}
      >
        <h2 className="text-2xl font-semibold font-heading text-grey-100 mb-6">
          Your Personal Learning Hub
        </h2>

        <ol className="space-y-6">
          {[
            {
              number: "1.",
              title: "View & Manage Your Files",
              description:
                "Quickly access your uploaded files in our integrated PDF viewer. Keep all your study materials in one place and stay organized.",
            },
            {
              number: "2.",
              title: "AI-Powered Study Assistant",
              description:
                "Need help with your material? Our AI chatbot, powered by ChatGPT-like technology, answers questions and provides instant summaries.",
            },
            {
              number: "3.",
              title: "Take Notes While You Study",
              description:
                "Easily jot down insights and summaries directly alongside your study materials without switching between apps.",
            },
          ].map((item, index) => (
            <motion.li
              className="flex items-start"
              key={`step-${item.number}`}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, amount: 0.1 }}
              transition={{
                duration: 0.6,
                ease: "easeOut",
                delay: index * 0.2,
              }}
            >
              <div className="flex-shrink-0 mr-4">
                <div className="flex items-center justify-center w-8 h-8 rounded-full bg-blue-500 font-heading text-base-white text-lg font-bold">
                  {item.number}
                </div>
              </div>
              <div>
                <h3 className="text-xl font-semibold font-heading text-base-white">
                  {item.title}
                </h3>
                <p className="text-grey-100">{item.description}</p>
              </div>
            </motion.li>
          ))}
        </ol>
      </motion.div>
    </motion.div>
  );
};

export default AboutLearningCenter;
