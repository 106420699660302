import Link from 'next/link';
import { ChevronDown } from 'lucide-react';
import type { Feature } from './types';

type NavLinksProps = {
  isActive: (path: string) => boolean;
  activeClass: string;
  inactiveClass: string;
  pathname: string;
  features: Feature[];
  featuresOpen: boolean;
  handleMouseEnter: () => void;
  handleMouseLeave: () => void;
};

export const NavLinks = ({
  isActive,
  activeClass,
  inactiveClass,
  pathname,
  features,
  featuresOpen,
  handleMouseEnter,
  handleMouseLeave,
}: NavLinksProps) => (
  <div className="hidden md:flex flex-grow justify-center items-center space-x-6">
    <Link
      className={`text-base-white rounded-md px-4 py-2 ${
        isActive('/aboutus') ? activeClass : inactiveClass
      }`}
      href="/aboutus"
    >
      About Us
    </Link>
    <Link
      className={`text-base-white rounded-md px-4 py-2 ${
        isActive('/#faq-section') ? activeClass : inactiveClass
      }`}
      href="/#faq-section"
    >
      FAQ
    </Link>
    <div className="relative" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <button
        className={`flex items-center rounded-md px-4 py-2 ${
          pathname.startsWith('/features') ? activeClass : 'hover:bg-grey-600'
        }`}
        type="button"
      >
        Features
        <ChevronDown className="ml-1" />
      </button>
      {featuresOpen && (
        <div
          className="bg-grey-800 absolute left-0 z-50 mt-2 w-48 rounded-md py-1 shadow-lg"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {features.map((feature) => (
            <Link
              className={`block px-4 py-2 ${
                isActive(feature.url)
                  ? 'bg-gray-700 text-sky-500'
                  : 'hover:bg-gray-700 hover:text-sky-500'
              }`}
              href={feature.url}
              key={feature.id}
            >
              {feature.name}
            </Link>
          ))}
        </div>
      )}
    </div>
  </div>
);
