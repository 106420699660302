"use client";

import { motion } from "motion/react";
import Image from "next/image";
import { childVariants } from "../_lib/animations";
import coursesImage from "@/public/images/courses.webp";

interface AboutCoursesProps {
  title: string;
  description: string;
}

function AboutCourses({ title, description }: AboutCoursesProps) {
  return (
    <div className="flex flex-col items-center px-4 pb-8 text-center">
      <motion.h3
        className="mb-4 text-2xl font-semibold font-heading text-grey-100"
        variants={childVariants}
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.7, ease: "easeOut" }}
      >
        {title}
      </motion.h3>
      <motion.p
        className="mb-6 text-center max-w-prose text-lg text-grey-300"
        variants={childVariants}
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.7, ease: "easeOut", delay: 0.1 }}
      >
        {description}
      </motion.p>
      <motion.div
        className="w-full"
        variants={childVariants}
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.7, ease: "easeOut", delay: 0.2 }}
      >
        <Image
          alt="About Courses"
          className="h-auto w-full"
          height={500}
          src={coursesImage}
          width={800}
          priority
        />
      </motion.div>
    </div>
  );
}

export default AboutCourses;
